import { ofType } from "redux-observable";
import { of, from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { ActionTypes, Actions } from "../actions";
import * as api from "../api";

export const getScoreboardList = action$ =>
  action$.pipe(
    ofType(ActionTypes.GET_SCOREBOARD_LIST),
    switchMap(action =>
      from(api.getScoreboardList(action.payload)).pipe(
        map(res => Actions.GET_SCOREBOARD_LIST_RESULT(null, res.data)),
        catchError(err => of(Actions.GET_SCOREBOARD_LIST_RESULT(err.message)))
      )
    )
  );
