import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import moment from 'moment'
import 'moment/locale/zh-tw'

import Header from "./containers/header";
import HomePage from "./pages/home";
import AdminContests from "./containers/admin_contests";
import AdminContestNew from "./containers/admin_contest_new";
import AdminContestEdit from "./containers/admin_contest_edit";

import AdminProblems from "./containers/admin_problems";
import AdminProblemNew from "./containers/admin_problem_new";
import AdminProblemEdit from "./containers/admin_problem_edit";
import Problems from "./containers/problems";
import Problem from "./containers/problem";
import Contests from "./containers/contests";
import Contest from "./containers/contest";

import "draft-js/dist/Draft.css";
import "react-mde/lib/styles/css/react-mde-all.css";
import "./tachyons.min.css"

import { Layout } from "antd";

const { Content, Footer } = Layout;

moment.locale('zh-tw')
class App extends Component {
  render() {
    return (
      <Router>
        <Layout className="layout">
          <Header />
          <Content
            style={{
              padding: "0 50px",
              marginTop: 64,
              minHeight: "calc(100vh - 64px - 69px)"
            }}
          >
            <div
              className="bg-white"
              style={{ margin: "16px 0", padding: 24, minHeight: "280px" }}
            >
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/problems/:id" component={Problem} />
                <Route path="/problems" component={Problems} />
                <Route path="/contests/:id" component={Contest} />
                <Route path="/contests" component={Contests} />
                <Route path="/admin/contests/new" component={AdminContestNew} />
                <Route
                  path="/admin/contests/:id"
                  component={AdminContestEdit}
                />
                <Route path="/admin/contests" component={AdminContests} />
                <Route path="/admin/problems/new" component={AdminProblemNew} />
                <Route
                  path="/admin/problems/:id"
                  component={AdminProblemEdit}
                />
                <Route path="/admin/problems" component={AdminProblems} />
              </Switch>
            </div>
          </Content>
          <Footer className="tc">
            Made with <span style={{ color: "#e25555" }}>&#9829;</span> by{" "}
            <a
              href="https://github.com/aszx87410"
              rel="noopener noreferrer"
              target="_blank"
            >
              huli
            </a>
          </Footer>
        </Layout>
      </Router>
    );
  }
}

export default App;
