import { ofType } from "redux-observable";
import { of, from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { ActionTypes, Actions } from "../actions";
import * as api from "../api";

export const getSubmissionList = action$ =>
  action$.pipe(
    ofType(ActionTypes.GET_SUBMISSION_LIST),
    switchMap(action =>
      from(api.getSubmissionList(action.payload)).pipe(
        map(res => Actions.GET_SUBMISSION_LIST_RESULT(null, res.data)),
        catchError(err => of(Actions.GET_SUBMISSION_LIST_RESULT(err.message)))
      )
    )
  );

export const createSubmission = action$ =>
  action$.pipe(
    ofType(ActionTypes.CREATE_SUBMISSION),
    switchMap(action =>
      from(api.createSubmission(action.payload)).pipe(
        map(res => {
          const data = res.data;
          api.judge(data.id);
          return Actions.CREATE_SUBMISSION_RESULT(null, data);
        }),
        catchError(err => of(Actions.CREATE_SUBMISSION_RESULT(err.message)))
      )
    )
  );
