import { ActionTypes } from "../actions";

const defaultState = {
  contestList: [],
  contest: {},
  isLoadingGetContestList: false,
  isLoadingGetContest: false,
  isLoadingCreateContest: false,
  isLoadingDeleteContest: false,
  isLoadingUpdateContest: false,
  getContestListError: null,
  getContestError: null,
  updateContestError: null,
  createContestError: null
};

function contestReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.CREATE_CONTEST:
      return {
        ...state,
        isLoadingCreateContest: true,
        createContestError: null
      };

    case ActionTypes.CREATE_CONTEST_RESULT:
      return {
        ...state,
        isLoadingCreateContest: false,
        createContestError: action.error
      };

    case ActionTypes.GET_CONTEST_LIST:
      return {
        ...state,
        isLoadingGetContestList: true,
        getContestListError: null
      };

    case ActionTypes.GET_CONTEST_LIST_RESULT:
      return {
        ...state,
        isLoadingGetContestList: false,
        getContestListError: action.error,
        contestList: action.list
      };

    case ActionTypes.DELETE_CONTEST:
      return {
        ...state,
        isLoadingDeleteContest: true
      };

    case ActionTypes.DELETE_CONTEST_RESULT:
      return {
        ...state,
        isLoadingDeleteContest: false
      };

    case ActionTypes.GET_CONTEST:
      return {
        ...state,
        isLoadingGetContest: true,
        getContestError: null
      };

    case ActionTypes.GET_CONTEST_RESULT:
      return {
        ...state,
        isLoadingGetContest: false,
        contest: action.item
      };

    case ActionTypes.UPDATE_CONTEST:
      return {
        ...state,
        isLoadingUpdateContest: true,
        updateContestError: null
      };

    case ActionTypes.UPDATE_CONTEST_RESULT:
      return {
        ...state,
        isLoadingUpdateContest: false,
        updateContestError: action.error
      };

    default:
      return state;
  }
}

export { contestReducer, defaultState };
