import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Contests from "../components/contests";
import { Actions } from "../actions";

const ContestsContainer = props => <Contests {...props} />;

const mapStateToProps = store => ({
  isLoadingGetContestList: store.contest.isLoadingGetContestList,
  contestList: store.contest.contestList
});

const mapDispatchToProps = {
  getContestList: Actions.GET_CONTEST_LIST
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContestsContainer)
);
