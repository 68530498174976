import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminProblemEdit from "../components/admin_problem_edit";
import { Actions } from "../actions";

const AdminProblemEditContainer = props => <AdminProblemEdit {...props} />;

const mapStateToProps = store => ({
  isLoadingGetProblem: store.problem.isLoadingGetProblem,
  isLoadingUpdateProblem: store.problem.isLoadingUpdateProblem,
  problem: store.problem.problem,
  updateProblemError: store.problem.updateProblemError
});

const mapDispatchToProps = {
  getProblem: Actions.GET_PROBLEM,
  updateProblem: Actions.UPDATE_PROBLEM
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminProblemEditContainer)
);
