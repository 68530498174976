import { ofType } from "redux-observable";
import { of, from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { ActionTypes, Actions } from "../actions";
import * as api from "../api";

export const getContestList = action$ =>
  action$.pipe(
    ofType(ActionTypes.GET_CONTEST_LIST),
    switchMap(action =>
      from(api.getContestList(action.payload)).pipe(
        map(res => Actions.GET_CONTEST_LIST_RESULT(null, res.data)),
        catchError(err => of(Actions.GET_CONTEST_LIST_RESULT(err.message)))
      )
    )
  );

export const getContest = action$ =>
  action$.pipe(
    ofType(ActionTypes.GET_CONTEST),
    switchMap(action =>
      from(api.getContest(action.id)).pipe(
        map(res => Actions.GET_CONTEST_RESULT(res.data)),
        catchError(err => of(Actions.GET_CONTEST_RESULT(err.message)))
      )
    )
  );

export const createContest = action$ =>
  action$.pipe(
    ofType(ActionTypes.CREATE_CONTEST),
    switchMap(action =>
      from(api.createContest(action.payload)).pipe(
        map(data => Actions.CREATE_CONTEST_RESULT(null)),
        catchError(err => of(Actions.CREATE_CONTEST_RESULT(err.message)))
      )
    )
  );

export const deleteContest = action$ =>
  action$.pipe(
    ofType(ActionTypes.DELETE_CONTEST),
    switchMap(action =>
      from(api.deleteContest(action.id)).pipe(
        map(data => Actions.DELETE_CONTEST_RESULT(null)),
        catchError(err => of(Actions.DELETE_CONTEST_RESULT(err.message)))
      )
    )
  );

export const updateContest = action$ =>
  action$.pipe(
    ofType(ActionTypes.UPDATE_CONTEST),
    switchMap(action =>
      from(api.updateContest(action.id, action.payload)).pipe(
        map(data => Actions.UPDATE_CONTEST_RESULT(null)),
        catchError(err => of(Actions.UPDATE_CONTEST_RESULT(err.message)))
      )
    )
  );
