import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminContestNew from "../components/admin_contest_new";
import { Actions } from "../actions";

const AdminContestNewContainer = props => <AdminContestNew {...props} />;

const mapStateToProps = store => ({
  isLoadingCreateContest: store.contest.isLoadingCreateContest,
  createContestError: store.contest.createContestError
});

const mapDispatchToProps = {
  createContest: Actions.CREATE_CONTEST
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminContestNewContainer)
);
