import EasyActions from "redux-easy-actions";

const { Actions, Constants } = EasyActions({
  SET_CHECK_LOGIN_STATUS(type, status) {
    return { type, status };
  },

  SET_USER_AUTHENTICATED(type, isLogin) {
    return { type, isLogin };
  },

  SET_USER(type, user) {
    return { type, user };
  },

  LOGOUT(type) {
    return { type };
  },

  // contest
  CREATE_CONTEST(type, payload) {
    return { type, payload };
  },

  CREATE_CONTEST_RESULT(type, error) {
    return { type, error };
  },

  GET_CONTEST_LIST(type, payload) {
    return { type, payload };
  },

  GET_CONTEST_LIST_RESULT(type, error, list) {
    return { type, error, list };
  },

  DELETE_CONTEST(type, id) {
    return { type, id };
  },

  DELETE_CONTEST_RESULT(type, error) {
    return { type, error };
  },

  GET_CONTEST(type, id) {
    return { type, id };
  },

  GET_CONTEST_RESULT(type, item) {
    return { type, item };
  },

  UPDATE_CONTEST(type, id, payload) {
    return { type, id, payload };
  },

  UPDATE_CONTEST_RESULT(type, error) {
    return { type, error };
  },

  // problem
  CREATE_PROBLEM(type, payload) {
    return { type, payload };
  },

  CREATE_PROBLEM_RESULT(type, error) {
    return { type, error };
  },

  GET_PROBLEM_LIST(type, payload) {
    return { type, payload };
  },

  GET_PROBLEM_LIST_RESULT(type, error, list) {
    return { type, error, list };
  },

  DELETE_PROBLEM(type, id) {
    return { type, id };
  },

  DELETE_PROBLEM_RESULT(type, error) {
    return { type, error };
  },

  GET_PROBLEM(type, id) {
    return { type, id };
  },

  GET_PROBLEM_RESULT(type, item) {
    return { type, item };
  },

  UPDATE_PROBLEM(type, id, payload) {
    return { type, id, payload };
  },

  UPDATE_PROBLEM_RESULT(type, error) {
    return { type, error };
  },

  // submission
  CREATE_SUBMISSION(type, payload) {
    return { type, payload };
  },

  CREATE_SUBMISSION_RESULT(type, error, item) {
    return { type, error, item };
  },

  GET_SUBMISSION_LIST(type, payload) {
    return { type, payload };
  },

  GET_SUBMISSION_LIST_RESULT(type, error, list) {
    return { type, error, list };
  },

  // scoreboard
  GET_SCOREBOARD_LIST(type, payload) {
    return { type, payload };
  },

  GET_SCOREBOARD_LIST_RESULT(type, error, list) {
    return { type, error, list };
  }
});

export { Actions, Constants as ActionTypes };
