const config = {
  firebase: {
    apiKey: "AIzaSyAv3N-qY_YBaxdq3HujX2rNw9On1ZwJydQ",
    authDomain: "lidemy-oj.firebaseapp.com",
    databaseURL: "https://lidemy-oj.firebaseio.com",
    projectId: "lidemy-oj",
    storageBucket: "lidemy-oj.appspot.com",
    messagingSenderId: "755861361251"
  },
  apiHost: "https://api.qootest.com"
};

export default config;
