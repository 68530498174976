import { combineReducers } from "redux";

import { authReducer as auth } from "./auth";
import { contestReducer as contest } from "./contest";
import { problemReducer as problem } from "./problem";
import { submissionReducer as submission } from "./submission";
import { scoreboardReducer as scoreboard } from "./scoreboard";

const reducer = combineReducers({
  auth,
  contest,
  problem,
  submission,
  scoreboard
});

export default reducer;
