import React, { Component } from "react";
import { Button, Form, Input, message, DatePicker, Select } from "antd";
import { Link } from "react-router-dom";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

class AdminContestEdit extends Component {
  constructor(props) {
    super(props);

    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }

  componentDidMount() {
    const { match, getContest } = this.props;
    const id = match.params.id;
    getContest(id);
  }

  componentDidUpdate(prevProps) {
    const {
      updateContestError,
      isLoadingUpdateContest,
      history,
      isLoadingGetContest,
      contest,
      form
    } = this.props;
    if (
      prevProps.isLoadingUpdateContest !== isLoadingUpdateContest &&
      !isLoadingUpdateContest
    ) {
      if (updateContestError) {
        message.error(updateContestError);
      } else {
        message.success("更改成功");
        history.push("/admin/contests");
      }
    }

    if (
      prevProps.isLoadingGetContest !== isLoadingGetContest &&
      !isLoadingGetContest
    ) {
      form.setFieldsValue({
        title: contest.title,
        deadline: moment(contest.deadline),
        status: contest.status,
        content: contest.content
      });
    }
  }

  handleValueChange = value => {
    this.setState({ value });
  };

  handleSubmit = e => {
    const { form, updateContest, match } = this.props;
    const id = match.params.id;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return message.error(JSON.stringify(err));
      }
      updateContest(id, {
        ...values,
        deadline: values["deadline"].format("YYYY-MM-DD HH:mm:ss")
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div>
          <Link to="/admin/contests">
            <Button type="primary">回上一頁</Button>
          </Link>
        </div>
        <div>
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} label="比賽名稱">
              {getFieldDecorator("title", {
                rules: [{ required: true, message: "required" }]
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="結束時間">
              {getFieldDecorator("deadline", {
                rules: [{ required: true, message: "required" }]
              })(<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="狀態">
              {getFieldDecorator("status", {
                rules: [{ required: true, message: "required" }]
              })(
                <Select>
                  <Option value="PENDING">準備中</Option>
                  <Option value="RUNNING">開始</Option>
                  <Option value="END">已結束</Option>
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="內容">
              {getFieldDecorator("content", {
                rules: [{ required: true, message: "required" }]
              })(
                <ReactMde
                  generateMarkdownPreview={markdown =>
                    Promise.resolve(this.converter.makeHtml(markdown))
                  }
                />
              )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                送出
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

const AdminContestEditForm = Form.create()(AdminContestEdit);

export default AdminContestEditForm;
