import React, { Component } from "react";
import { Button, Form, Input, message, DatePicker } from "antd";
import { Link } from "react-router-dom";
import ReactMde from "react-mde";
import * as Showdown from "showdown";

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

class AdminContestNew extends Component {
  constructor(props) {
    super(props);

    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }

  componentDidUpdate(prevProps) {
    const { createContestError, isLoadingCreateContest, history } = this.props;
    if (
      prevProps.isLoadingCreateContest !== isLoadingCreateContest &&
      !isLoadingCreateContest
    ) {
      if (createContestError) {
        message.error(createContestError);
      } else {
        message.success("建立成功");
        history.push("/admin/contests");
      }
    }
  }

  handleValueChange = value => {
    this.setState({ value });
  };

  handleSubmit = e => {
    const { form, createContest } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return message.error(JSON.stringify(err));
      }
      createContest({
        ...values,
        status: "PENDING",
        deadline: values["deadline"].format("YYYY-MM-DD HH:mm:ss")
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div>
          <Link to="/admin/contests">
            <Button type="primary">回上一頁</Button>
          </Link>
        </div>
        <div>
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} label="比賽名稱">
              {getFieldDecorator("title", {
                rules: [{ required: true, message: "required" }]
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="結束時間">
              {getFieldDecorator("deadline", {
                rules: [{ required: true, message: "required" }]
              })(<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="內容">
              {getFieldDecorator("content", {
                rules: [{ required: true, message: "required" }]
              })(
                <ReactMde
                  generateMarkdownPreview={markdown =>
                    Promise.resolve(this.converter.makeHtml(markdown))
                  }
                />
              )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                送出
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

const AdminContestNewForm = Form.create()(AdminContestNew);

export default AdminContestNewForm;
