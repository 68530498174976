import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Problem from "../components/problem";
import { Actions } from "../actions";

const ProblemContainer = props => <Problem {...props} />;

const mapStateToProps = store => ({
  isLoadingGetProblem: store.problem.isLoadingGetProblem,
  isLoadingCreateSubmission: store.submission.isLoadingCreateSubmission,
  isLoadingGetSubmissionList: store.submission.isLoadingGetSubmissionList,
  createSubmissionError: store.submission.createSubmissionError,
  submissionList: store.submission.submissionList,
  createdSubmission: store.submission.createdSubmission,
  problem: store.problem.problem,
  user: store.auth.user
});

const mapDispatchToProps = {
  getProblem: Actions.GET_PROBLEM,
  createSubmission: Actions.CREATE_SUBMISSION,
  getSubmissionList: Actions.GET_SUBMISSION_LIST
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProblemContainer)
);
