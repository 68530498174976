import { ActionTypes } from "../actions";

const defaultState = {
  error: null,
  loginStatus: "LOADING",
  isLogin: false,
  user: null
};

function authReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.SET_CHECK_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.status
      };

    case ActionTypes.SET_USER_AUTHENTICATED:
      return {
        ...state,
        isLogin: true
      };

    case ActionTypes.SET_USER:
      return {
        ...state,
        user: action.user
      };

    case ActionTypes.LOGOUT:
      return {
        ...state,
        isLogin: false,
        user: null
      };

    default:
      return state;
  }
}

export { authReducer, defaultState };
