import axios from "axios";
import config from "./config";
import storage from "./utils/storage";

const instance = axios.create({
  baseURL: config.apiHost
});

instance.interceptors.request.use(config => {
  config.headers.Authorization = `token ${storage.getToken()}`;
  return config;
});

export const createContest = values => instance.post("/contests", values);
export const getContestList = (params = {}) =>
  instance.get("/contests", { params });
export const deleteContest = id => instance.delete("/contests/" + id);
export const getContest = id => instance.get("/contests/" + id);
export const updateContest = (id, params) =>
  instance.put("/contests/" + id, params);

export const createProblem = values => instance.post("/problems", values);
export const getProblemList = (params = {}) =>
  instance.get("/problems", { params });
export const deleteProblem = id => instance.delete("/problems/" + id);
export const getProblem = id => instance.get("/problems/" + id);
export const updateProblem = (id, params) =>
  instance.put("/problems/" + id, params);

export const createSubmission = values => instance.post("/submissions", values);
export const getSubmissionList = (params = {}) =>
  instance.get("/submissions", { params });

export const getScoreboardList = (params = {}) =>
  instance.get("/scoreboards", { params });

export const judge = id => instance.get("/judge/" + id);
