import React, { Component } from "react";
import { Table } from "antd";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

class Problems extends Component {
  componentDidMount() {
    this.props.getProblemList({
      status: "RUNNING"
    });
  }

  getColumns = () => {
    const columns = [
      {
        title: "標題",
        dataIndex: "title",
        key: "title",
        render: (text, record) => (
          <Link to={`/problems/${record.id}`}>{text}</Link>
        )
      },
      {
        title: "建立時間",
        dataIndex: "createdAt",
        key: "createdAt",
        render: createdAt => <span>{moment(createdAt).format('YYYY-MM-DD')}</span>
      }
    ];
    return columns;
  };

  render() {
    const { problemList, isLoadingGetProblemList } = this.props;
    return (
      <div>
        <h1>題目列表</h1>
        <div>
          <Table
            loading={isLoadingGetProblemList}
            columns={this.getColumns()}
            dataSource={problemList}
            rowKey="id"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Problems);
