import React, { Component } from "react";
import { Button, Form, Input, message, Select } from "antd";
import { Link } from "react-router-dom";
import ReactMde from "react-mde";
import * as Showdown from "showdown";

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

class AdminProblemEdit extends Component {
  constructor(props) {
    super(props);

    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }

  componentDidMount() {
    const { match, getProblem } = this.props;
    const id = match.params.id;
    getProblem(id);
  }

  componentDidUpdate(prevProps) {
    const {
      updateProblemError,
      isLoadingUpdateProblem,
      history,
      isLoadingGetProblem,
      problem,
      form
    } = this.props;
    if (
      prevProps.isLoadingUpdateProblem !== isLoadingUpdateProblem &&
      !isLoadingUpdateProblem
    ) {
      if (updateProblemError) {
        message.error(updateProblemError);
      } else {
        message.success("更改成功");
        history.push("/admin/problems");
      }
    }

    if (
      prevProps.isLoadingGetProblem !== isLoadingGetProblem &&
      !isLoadingGetProblem
    ) {
      form.setFieldsValue({
        title: problem.title,
        funcName: problem.funcName,
        contestId: problem.contestId,
        status: problem.status,
        content: problem.content,
        testCases: problem.testCases
      });
    }
  }

  handleValueChange = value => {
    this.setState({ value });
  };

  handleSubmit = e => {
    const { form, updateProblem, match } = this.props;
    const id = match.params.id;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return message.error(JSON.stringify(err));
      }
      updateProblem(id, {
        ...values
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div>
          <Link to="/admin/problems">
            <Button type="primary">回上一頁</Button>
          </Link>
        </div>
        <div>
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} label="題目名稱">
              {getFieldDecorator("title", {
                rules: [{ required: true, message: "required" }]
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="contestId">
              {getFieldDecorator("contestId", {
                rules: [{ required: true, message: "required" }]
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="funcName">
              {getFieldDecorator("funcName", {
                rules: [{ required: true, message: "required" }]
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="狀態">
              {getFieldDecorator("status", {
                rules: [{ required: true, message: "required" }]
              })(
                <Select>
                  <Option value="PENDING">準備中</Option>
                  <Option value="RUNNING">開始</Option>
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="內容">
              {getFieldDecorator("content", {
                rules: [{ required: true, message: "required" }]
              })(
                <ReactMde
                  generateMarkdownPreview={markdown =>
                    Promise.resolve(this.converter.makeHtml(markdown))
                  }
                />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="testCases">
              {getFieldDecorator("testCases", {
                rules: [{ required: true, message: "required" }]
              })(<TextArea rows={10} />)}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                送出
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

const AdminProblemEditForm = Form.create()(AdminProblemEdit);

export default AdminProblemEditForm;
