import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminProblemNew from "../components/admin_problem_new";
import { Actions } from "../actions";

const AdminProblemNewContainer = props => <AdminProblemNew {...props} />;

const mapStateToProps = store => ({
  isLoadingCreateProblem: store.problem.isLoadingCreateProblem,
  createProblemError: store.problem.createProblemError
});

const mapDispatchToProps = {
  createProblem: Actions.CREATE_PROBLEM
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminProblemNewContainer)
);
