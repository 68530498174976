import React, { Component } from "react";
import { Table } from "antd";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

const mappingStatus = {
  'RUNNING': '進行中',
  'END': '已結束'
}

class Contests extends Component {
  componentDidMount() {
    this.props.getContestList();
  }

  getColumns = () => {
    const columns = [
      {
        title: "標題",
        dataIndex: "title",
        key: "title",
        render: (text, record) => (
          <Link to={`/contests/${record.id}`}>{text}</Link>
        )
      },
      {
        title: "狀態",
        dataIndex: "status",
        key: "status",
        render: status => <span>{mappingStatus[status]}</span>
      },
      {
        title: "建立時間",
        dataIndex: "createdAt",
        key: "createdAt",
        render: createdAt => <span>{moment(createdAt).fromNow() + ' (' + moment(createdAt).format('YYYY-MM-DD HH:mm:ss') + ')'}</span>
      },
      {
        title: "結束時間",
        dataIndex: "deadline",
        key: "deadline",
        render: deadline => <span>{moment(deadline).fromNow() + ' (' + moment(deadline).format('YYYY-MM-DD HH:mm:ss') + ')'}</span>
      }
    ];
    return columns;
  };

  render() {
    const { contestList, isLoadingGetContestList } = this.props;
    return (
      <div>
        <h1>比賽列表</h1>
        <div>
          <Table
            loading={isLoadingGetContestList}
            columns={this.getColumns()}
            dataSource={contestList.filter(item => item.status !== 'PENDING')}
            rowKey="id"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Contests);
