import { ActionTypes } from "../actions";

const defaultState = {
  submissionList: [],
  isLoadingGetSubmissionList: false,
  isLoadingCreateSubmission: false,
  getSubmissionListError: null,
  createSubmissionError: null,
  createdSubmission: {}
};

function submissionReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.CREATE_SUBMISSION:
      return {
        ...state,
        isLoadingCreateSubmission: true,
        createSubmissionError: null,
        createdSubmission: {}
      };

    case ActionTypes.CREATE_SUBMISSION_RESULT:
      return {
        ...state,
        isLoadingCreateSubmission: false,
        createdSubmission: action.item,
        createSubmissionError: action.error
      };

    case ActionTypes.GET_SUBMISSION_LIST:
      return {
        ...state,
        isLoadingGetSubmissionList: true,
        getSubmissionListError: null
      };

    case ActionTypes.GET_SUBMISSION_LIST_RESULT:
      return {
        ...state,
        isLoadingGetSubmissionList: false,
        getSubmissionListError: action.error,
        submissionList: action.list
      };

    default:
      return state;
  }
}

export { submissionReducer, defaultState };
