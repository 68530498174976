import React, { Component } from "react";
import { Button, Table, Spin, Icon } from "antd";
import * as Showdown from "showdown";
import {Link} from 'react-router-dom'
import moment from "moment";
import lodash from "lodash";

class Contest extends Component {
  constructor(props) {
    super(props);

    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }

  componentDidMount() {
    const { match, getContest } = this.props;
    const id = match.params.id;
    getContest(id);
    this.getInfo();
  }

  getColumns = () => {
    const columns = [
      {
        title: "題目",
        dataIndex: "title",
        key: "title",
        render: (data, record) => (
          <Link to={"/problems/" + record.id}>{data}</Link>
        )
      }
    ];
    return columns;
  };

  getInfo = () => {
    const { match, getProblemList, getScoreboardList } = this.props;
    const id = match.params.id;
    getProblemList({
      contestId: id,
      sort: "createdAt"
    });
    getScoreboardList({
      contestId: id
    });
  }

  refresh = () => {
    const { match, getScoreboardList } = this.props;
    const id = match.params.id;
    getScoreboardList({
      contestId: id
    });
  }

  getScoreColumns = () => {
    const { problemList } = this.props;
    const columns = [
      {
        title: "排名",
        key: "rank",
        render: (data, record, index) => index + 1
      },
      {
        title: "帳號",
        dataIndex: "email",
        key: "email"
      }
    ];

    for (let i = 0; i < problemList.length; i++) {
      columns.push({
        title: problemList[i].title,
        key: "p" + i,
        render: (data, record) => {
          const score = Number(record.scores[problemList[i].id]) || 0;
          let color = "";
          if (score === 100) {
            color = "green";
          } else if (score > 0) {
            color = "red";
          }
          return (
            <div
              style={{
                color: color
              }}
            >
              {score}
            </div>
          );
        }
      });
    }

    columns.push({
      title: "總分",
      dataIndex: "total",
      key: "total"
    });

    return columns;
  };

  getScoreboard = () => {
    const { scoreboardList } = this.props;

    return scoreboardList
      .map(item => {
        const scores = JSON.parse(item.scores) || [];
        const total = lodash.reduce(
          scores,
          (result, value) => {
            return value + result;
          },
          0
        );
        return {
          ...item,
          scores,
          total
        };
      })
      .sort((a, b) => {
        if (a.total !== b.total) return b.total - a.total;
        return (
          moment(a.updatedAt).format("x") - moment(b.updatedAt).format("x")
        );
      });
  };

  render() {
    const { contest, problemList, isLoadingGetContest, isLoadingGetScoreboardList } = this.props;
    return (
      <div>
        <div>
          <Link to="/contests">
            <Button type="primary">回上一頁</Button>
          </Link>
        </div>
        <Spin spinning={isLoadingGetContest}>
          <div>
            <h1>比賽名稱：{contest.title}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: this.converter.makeHtml(contest.content)
              }}
            />
          </div>
          <div>
            <h1>排行榜{' '}<Button onClick={this.refresh}><Icon type='reload' /></Button></h1>
            <Table
              loading={isLoadingGetScoreboardList}
              columns={this.getScoreColumns()}
              dataSource={this.getScoreboard()}
              rowKey="id"
            />
          </div>
          <div>
            <h1>題目列表</h1>
            <Table
              columns={this.getColumns()}
              dataSource={problemList}
              rowKey="id"
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default Contest;
