import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminContests from "../components/admin_contests";
import { Actions } from "../actions";

const AdminContestsContainer = props => <AdminContests {...props} />;

const mapStateToProps = store => ({
  contestList: store.contest.contestList,
  isLoadingDeleteContest: store.contest.isLoadingDeleteContest
});

const mapDispatchToProps = {
  getContestList: Actions.GET_CONTEST_LIST,
  deleteContest: Actions.DELETE_CONTEST
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminContestsContainer)
);
