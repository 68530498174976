import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Contest from "../components/contest";
import { Actions } from "../actions";

const ContestContainer = props => <Contest {...props} />;

const mapStateToProps = store => ({
  isLoadingGetScoreboardList: store.scoreboard.isLoadingGetScoreboardList,
  isLoadingGetContest: store.contest.isLoadingGetContest,
  contest: store.contest.contest,
  problemList: store.problem.problemList,
  scoreboardList: store.scoreboard.scoreboardList,
  user: store.auth.user
});

const mapDispatchToProps = {
  getContest: Actions.GET_CONTEST,
  getProblemList: Actions.GET_PROBLEM_LIST,
  getScoreboardList: Actions.GET_SCOREBOARD_LIST
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContestContainer)
);
