import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
      <div>
        <h1>Lidemy OJ</h1>
        <p>hey，這裡是 Lidemy OJ（Online Judge），一個非常簡單的 MVP，功能十分不完整但堪用</p>
        <p>使用 GitHub 登入以後便能夠提交答案，這邊的程式碼皆為實作出一個 function，而 function 名稱請參考題目說明</p>
        <p>建議在自己電腦上測試過後確認沒問題再把程式碼貼上來提交</p>
        <p>另外，本站仍處於測試狀態，解題的原始碼請自己保留，因為這個站隨時都有可能掛掉XD</p>
        <h2>歷史紀錄狀態說明</h2>
        <ul>
          <li>AC：Accepted，正確答案</li>
          <li>WA：Wrong Answer，錯誤答案</li>
          <li>RE：Runtime Error，執行時錯誤</li>
          <li>TLE：Time Limit Exceed，超時</li>
        </ul>
        <h2>跟其他 OJ 的差異</h2>
        <p>
          為了方便教學，因此這個 OJ 跟外面一般大家在講的 OJ 有一定的差異，像是這裡有分數，而不是只有 WA 或 AC<br />
          然後這邊也沒有一般常見的記憶體跟時間限制，也不用自己處理 IO，所有的輸出入都是以 function 為主 <br/>
          這是為了降低難度而做出的改變
        </p>
      </div>
    );
  }
}

export default Header;
