import React, { Component } from "react";
import { Button, Form, Input, message, Table, Spin, Icon } from "antd";
import * as Showdown from "showdown";
import moment from "moment";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

class Problem extends Component {
  constructor(props) {
    super(props);

    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });

  }

  componentDidMount() {
    const { match, getProblem } = this.props;
    const id = match.params.id;
    getProblem(id);
    this.getSubmissions();
  }

  componentDidUpdate(prevProps) {
    const {
      createSubmissionError,
      isLoadingCreateSubmission
    } = this.props;
    if (
      prevProps.isLoadingCreateSubmission !== isLoadingCreateSubmission &&
      !isLoadingCreateSubmission
    ) {
      if (createSubmissionError) {
        message.error(createSubmissionError);
      } else {
        message.success("提交成功");
        this.getSubmissions();
        this.timer = setTimeout(() => {
          this.getSubmissions()
        }, 1000)
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this.timer = null
  }

  getColumns = () => {
    const columns = [
      {
        title: "帳號",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "分數",
        dataIndex: "score",
        key: "score"
      },
      {
        title: "狀態",
        dataIndex: "status",
        key: "status"
      },
      {
        title: "送出時間",
        dataIndex: "createdAt",
        key: "createdAt",
        render: createdAt => <span>
          {moment(createdAt).fromNow() + ' (' + moment(createdAt).format('YYYY-MM-DD HH:mm:ss') + ')'}
        </span>
      }
    ];
    return columns;
  };

  getSubmissions = () => {
    const { match, getSubmissionList } = this.props;
    const id = match.params.id;
    getSubmissionList({
      problemId: id,
      sort: "-createdAt",
      count: 30
    });
  };

  handleSubmit = e => {
    const { form, createSubmission, user, match } = this.props;
    e.preventDefault();
    const id = match.params.id;
    form.validateFields((err, values) => {
      if (err) {
        return message.error(JSON.stringify(err));
      }
      createSubmission({
        ...values,
        problemId: Number(id),
        email: user.email,
        score: 0,
        status: "PENDING"
      });
      form.setFieldsValue({
        code: ""
      });
    });
  };

  render() {
    const { problem, user, submissionList, history, isLoadingGetSubmissionList, isLoadingGetProblem } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div>
          <Button type="primary" onClick={() => history.goBack()}>
            回上一頁
          </Button>
        </div>
        <Spin spinning={isLoadingGetProblem}>
          <div>
            <h1>題目名稱：{problem.title}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: this.converter.makeHtml(problem.content)
              }}
            />
          </div>
          <div>
            {user && (
              <Form onSubmit={this.handleSubmit}>
                <FormItem label="程式碼">
                  {getFieldDecorator("code", {
                    rules: [{ required: true, message: "required" }]
                  })(<TextArea rows={10} />)}
                </FormItem>
                <FormItem>
                  <Button type="primary" htmlType="submit">
                    送出
                  </Button>
                </FormItem>
              </Form>
            )}
          </div>
          <div>
            <h1>
              歷史紀錄{" "}
              <Button onClick={() => this.getSubmissions()}><Icon type='reload' /></Button>
            </h1>
            <Table
              columns={this.getColumns()}
              dataSource={submissionList}
              rowKey="id"
              loading={isLoadingGetSubmissionList}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const ProblemForm = Form.create()(Problem);

export default ProblemForm;
