import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminProblems from "../components/admin_problems";
import { Actions } from "../actions";

const AdminProblemsContainer = props => <AdminProblems {...props} />;

const mapStateToProps = store => ({
  problemList: store.problem.problemList,
  isLoadingDeleteProblem: store.problem.isLoadingDeleteProblem
});

const mapDispatchToProps = {
  getProblemList: Actions.GET_PROBLEM_LIST,
  deleteProblem: Actions.DELETE_PROBLEM
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminProblemsContainer)
);
