import React, { Component } from "react";
import { Layout, Menu, Button } from "antd";
import { Link } from "react-router-dom";
import { login, logout } from "../../utils/firebase";

const AntdHeader = Layout.Header;

class Header extends Component {
  getSelectedKey = () => {
    const { location } = this.props;
    const pathname = location.pathname;
    if (pathname.indexOf("/problems") === 0) return "problems";
    if (pathname.indexOf("/contests") === 0) return "contests";
    if (pathname.indexOf("/admin_contests") === 0) return "admin_contests";
    if (pathname.indexOf("/admin_problems") === 0) return "admin_problems";
    return "home";
  };

  handleLogin = () => {
    login();
  };

  handleLogout = () => {
    logout().then(() => {
      this.props.logout();
      window.location.reload();
    });
  };

  render() {
    const { user } = this.props;
    const isAdmin = user && user.email === "aszx87410@gmail.com";
    return (
      <AntdHeader className="fixed w-100 z-1">
        <div className="logo">Lidemy OJ</div>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={[this.getSelectedKey()]}
          style={{ lineHeight: "64px" }}
        >
          <Menu.Item key="home">
            <Link to="/">首頁</Link>
          </Menu.Item>
          <Menu.Item key="problems">
            <Link to="/problems">題目</Link>
          </Menu.Item>
          <Menu.Item key="contests">
            <Link to="/contests">比賽</Link>
          </Menu.Item>
          {isAdmin && (
            <Menu.Item key="admin_contests">
              <Link to="/admin/contests">管理比賽</Link>
            </Menu.Item>
          )}
          {isAdmin && (
            <Menu.Item key="admin_problems">
              <Link to="/admin/problems">管理題目</Link>
            </Menu.Item>
          )}
        </Menu>
        <div
          className="absolute white flex items-center"
          style={{
            right: "32px",
            top: 0,
            height: "64px"
          }}
        >
          {user && (
            <div>
              <img
                src={user.photoURL}
                alt="avatar"
                style={{
                  width: "42px",
                  height: "42px",
                  marginRight: "10px"
                }}
              />
            </div>
          )}
          {!user && (
            <Button type="primary" onClick={this.handleLogin}>
              登入
            </Button>
          )}
          {user && user.email && (
            <Button type="primary" onClick={this.handleLogout}>
              登出
            </Button>
          )}
        </div>
      </AntdHeader>
    );
  }
}

export default Header;
