import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminContestEdit from "../components/admin_contest_edit";
import { Actions } from "../actions";

const AdminContestEditContainer = props => <AdminContestEdit {...props} />;

const mapStateToProps = store => ({
  isLoadingGetContest: store.contest.isLoadingGetContest,
  isLoadingUpdateContest: store.contest.isLoadingUpdateContest,
  contest: store.contest.contest,
  updateContestError: store.contest.updateContestError
});

const mapDispatchToProps = {
  getContest: Actions.GET_CONTEST,
  updateContest: Actions.UPDATE_CONTEST
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminContestEditContainer)
);
