import { ofType } from "redux-observable";
import { of, from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { ActionTypes, Actions } from "../actions";
import * as api from "../api";

export const getProblemList = action$ =>
  action$.pipe(
    ofType(ActionTypes.GET_PROBLEM_LIST),
    switchMap(action =>
      from(api.getProblemList(action.payload)).pipe(
        map(res => Actions.GET_PROBLEM_LIST_RESULT(null, res.data)),
        catchError(err => of(Actions.GET_PROBLEM_LIST_RESULT(err.message)))
      )
    )
  );

export const getProblem = action$ =>
  action$.pipe(
    ofType(ActionTypes.GET_PROBLEM),
    switchMap(action =>
      from(api.getProblem(action.id)).pipe(
        map(res => Actions.GET_PROBLEM_RESULT(res.data)),
        catchError(err => of(Actions.GET_PROBLEM_RESULT(err.message)))
      )
    )
  );

export const createProblem = action$ =>
  action$.pipe(
    ofType(ActionTypes.CREATE_PROBLEM),
    switchMap(action =>
      from(api.createProblem(action.payload)).pipe(
        map(data => Actions.CREATE_PROBLEM_RESULT(null)),
        catchError(err => of(Actions.CREATE_PROBLEM_RESULT(err.message)))
      )
    )
  );

export const deleteProblem = action$ =>
  action$.pipe(
    ofType(ActionTypes.DELETE_PROBLEM),
    switchMap(action =>
      from(api.deleteProblem(action.id)).pipe(
        map(data => Actions.DELETE_PROBLEM_RESULT(null)),
        catchError(err => of(Actions.DELETE_PROBLEM_RESULT(err.message)))
      )
    )
  );

export const updateProblem = action$ =>
  action$.pipe(
    ofType(ActionTypes.UPDATE_PROBLEM),
    switchMap(action =>
      from(api.updateProblem(action.id, action.payload)).pipe(
        map(data => Actions.UPDATE_PROBLEM_RESULT(null)),
        catchError(err => of(Actions.UPDATE_PROBLEM_RESULT(err.message)))
      )
    )
  );
