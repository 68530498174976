import { ActionTypes } from "../actions";

const defaultState = {
  problemList: [],
  problem: {},
  isLoadingGetProblemList: false,
  isLoadingGetProblem: false,
  isLoadingCreateProblem: false,
  isLoadingDeleteProblem: false,
  isLoadingUpdateProblem: false,
  getProblemListError: null,
  getProblemError: null,
  updateProblemError: null,
  createProblemError: null
};

function problemReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.CREATE_PROBLEM:
      return {
        ...state,
        isLoadingCreateProblem: true,
        createProblemError: null
      };

    case ActionTypes.CREATE_PROBLEM_RESULT:
      return {
        ...state,
        isLoadingCreateProblem: false,
        createProblemError: action.error
      };

    case ActionTypes.GET_PROBLEM_LIST:
      return {
        ...state,
        isLoadingGetProblemList: true,
        getProblemListError: null
      };

    case ActionTypes.GET_PROBLEM_LIST_RESULT:
      return {
        ...state,
        isLoadingGetProblemList: false,
        getProblemListError: action.error,
        problemList: action.list
      };

    case ActionTypes.DELETE_PROBLEM:
      return {
        ...state,
        isLoadingDeleteProblem: true
      };

    case ActionTypes.DELETE_PROBLEM_RESULT:
      return {
        ...state,
        isLoadingDeleteProblem: false
      };

    case ActionTypes.GET_PROBLEM:
      return {
        ...state,
        isLoadingGetProblem: true,
        getProblemError: null
      };

    case ActionTypes.GET_PROBLEM_RESULT:
      return {
        ...state,
        isLoadingGetProblem: false,
        problem: action.item
      };

    case ActionTypes.UPDATE_PROBLEM:
      return {
        ...state,
        isLoadingUpdateProblem: true,
        updateProblemError: null
      };

    case ActionTypes.UPDATE_PROBLEM_RESULT:
      return {
        ...state,
        isLoadingUpdateProblem: false,
        updateProblemError: action.error
      };

    default:
      return state;
  }
}

export { problemReducer, defaultState };
