import { ActionTypes } from "../actions";

const defaultState = {
  scoreboardList: [],
  isLoadingGetScoreboardList: false,
  getScoreboardListError: null
};

function scoreboardReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.GET_SCOREBOARD_LIST:
      return {
        ...state,
        isLoadingGetScoreboardList: true,
        getScoreboardListError: null
      };

    case ActionTypes.GET_SCOREBOARD_LIST_RESULT:
      return {
        ...state,
        isLoadingGetScoreboardList: false,
        getScoreboardListError: action.error,
        scoreboardList: action.list
      };

    default:
      return state;
  }
}

export { scoreboardReducer, defaultState };
