import React, { Component } from "react";
import { Modal, Button, Table, message } from "antd";
import { Link, withRouter } from "react-router-dom";

const confirm = Modal.confirm;

class AdminProblems extends Component {
  componentDidMount() {
    this.props.getProblemList();
  }

  componentDidUpdate(prevProps) {
    const { isLoadingDeleteProblem, getProblemList } = this.props;
    if (
      prevProps.isLoadingDeleteProblem !== isLoadingDeleteProblem &&
      !isLoadingDeleteProblem
    ) {
      message.success("刪除成功");
      getProblemList();
    }
  }

  handleDelete = record => {
    const { deleteProblem } = this.props;
    confirm({
      title: "你確定要刪除嗎？",
      content: record.title,
      onOk() {
        deleteProblem(record.id);
      }
    });
  };

  getColumns = () => {
    const columns = [
      {
        title: "標題",
        dataIndex: "title",
        key: "title",
        render: (text, record) => (
          <Link to={`/admin/problems/${record.id}`}>{text}</Link>
        )
      },
      {
        title: "funcName",
        dataIndex: "funcName",
        key: "funcName"
      },
      {
        title: "狀態",
        dataIndex: "status",
        key: "status"
      },
      {
        title: "操作",
        key: "action",
        render: (text, record) => (
          <span>
            <a
              onClick={() => {
                this.handleDelete(record);
              }}
            >
              Delete
            </a>
          </span>
        )
      }
    ];
    return columns;
  };

  render() {
    const { problemList } = this.props;
    return (
      <div>
        <Link to="/admin/problems/new">
          <Button type="primary">新增題目</Button>
        </Link>
        <div>
          <Table
            columns={this.getColumns()}
            dataSource={problemList}
            rowKey="id"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(AdminProblems);
